<template>
    <section style="overflow: hidden;">
        <titlebar :title-stack="titleStack">
            <div slot="right" v-if="model.id">
                <span v-if="!model.temPendencia" class="tag is-success">{{$t('PACIENTECADASTRO.PENDENCIA')}}</span>
                <span v-else class="tag is-danger">
                    {{model.temPendenciaTexto}}
                </span>
            </div>
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="150" type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-2">
                                    <div class="field">
                                        <b-switch v-model="nomeSocial">
                                           {{$t('PACIENTECADASTRO.NOMESOCIAL')}}
                                        </b-switch>
                                    </div>
                                    <div class="field">
                                        <b-switch v-show="config.laboratorioVeterinario" v-model="model.animal">
                                            {{$t('PACIENTECADASTRO.ANIMAL')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>

                            <div class="columns" v-if="nomeSocial">
                                <div class="column">
                                    <b-field :label="$t('PACIENTECADASTRO.NOMESOCIAL')">
                                        <b-input maxlength="150" type="text" v-model="model.nomeSocial"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <buscaCepEndereco :cep.sync="model.cep"
                                              :endereco.sync="model.endereco"
                                              :bairro.sync="model.bairro"
                                              :cidadeid.sync="model.cidadeId"
                                              :cidadenome.sync="model.cidadeNome"></buscaCepEndereco>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('PACIENTECADASTRO.FONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('PACIENTECADASTRO.CELULAR')">
                                        <b-input expanded
                                                 v-model="model.celular" 
                                                 v-mask="['(##) ####-####', '(##) #####-####']"
                                                 :icon-right-clickable="podeEnviarSms"
                                                 @icon-right-click="abrirModalSms"
                                                 :icon-right="iconeEnviarSms"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.enviaSMS">
                                            {{$t('PACIENTECADASTRO.ENVIAMSG')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <ValidationProvider rules="required" name="sexo" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.SEXO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('PACIENTECADASTRO.SELECIONESEXO')" v-model="model.sexo">
                                                <option value="F">{{$t('PACIENTECADASTRO.FEM')}}</option>
                                                <option value="M">{{$t('PACIENTECADASTRO.MASC')}}</option>
                                                <option v-show="config.laboratorioVeterinario || config.permitirSexoIndeterminado" value="I">{{$t('PACIENTECADASTRO.INDT')}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-half-mobile">
                                    <ValidationProvider rules="required" name="data de nascimento" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.NASCIMENTO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker v-model="model.dataDeNascimento" trap-focus editable :max-date="new Date()" 
                                                :min-date="new Date(new Date().getFullYear() - 200, new Date().getMonth(), new Date().getDate())"></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.IDADE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" @change="alterouIdade" :value="idade">
                                        </div>
                                    </div>
                                </div>
                                <div v-show="config.laboratorioVeterinario" class="column is-half-mobile texto-labels">
                                    <label class="label">{{$t('PACIENTECADASTRO.CALCIDADE')}}</label>
                                    <b-select v-model="model.unidadeIdade">
                                        <option value="A">{{$t('PACIENTECADASTRO.ANOS')}}</option>
                                        <option value="M">{{$t('PACIENTECADASTRO.MES')}}</option>
                                        <option value="D">{{$t('PACIENTECADASTRO.DIA')}}</option>
                                    </b-select>
                                </div>
                                <div class="column is-half-mobile texto-labels">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.PESO')}}</label>
                                        <div class="control">
                                            <input class="input" type="number" step="0.01" v-model="model.peso">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-half-mobile texto-labels">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.ALTURA')}}</label>
                                        <div class="control">
                                            <input class="input" type="number" step="0.01" v-model="model.altura">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.EMAIL')"
                                                 ref="email"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="50" type="email" v-model="model.email" @blur="verificarEmail" :readonly="model.possuiUsuario"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.RG')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.rg" v-mask="'XXXXXXXXXXXXXXX'">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">                                    
                                    <ValidationProvider :rules="config.language == 'es' ? 'cpf:8' : 'cpf:14'" name="cpf" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.CPF')"
                                                 ref="cpf"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.cpf" v-mask="config.language == 'es' ? '########' : '###.###.###-##'"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.CARTAOSAUDE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cartaoNacionalDeSaude">
                                        </div>
                                    </div>
                                </div>
                            </div>

 
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.PLANO')}}</label>
                                        <div class="control">
                                            <input class="input" maxlength="40" type="text" v-model="model.plano">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.TITULAR')}}</label>
                                        <div class="control">
                                            <input class="input" maxlength="40" type="text" v-model="model.nomeDoTitular">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.PASSAPORTE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.passaporte">
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                           <div class="columns is-multiline is-mobile">
                                <div class="column is-three-quarters-mobile">
                                    <ValidationProvider 
                                    :rules="`inscricao:${validaCarteira}`" name="inscricao" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.INSCRICAO')"
                                            ref="inscricao"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors"
                                        >
                                            <b-input type="inscricao" v-model="model.inscricao" @blur="validaCarteiraPadrao"></b-input>
                                        </b-field>
                                    </ValidationProvider>



                                </div>
                                <div class="column is-2-desktop is-one-quarter-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.DV')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.inscricaoDV" v-mask="'#'">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.VALIDADE')}}</label>
                                        <div class="control">
                                            <b-datepicker editable v-model="model.validade" trap-focus></b-datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <searchIdName :id.sync="model.convenioPadraoId" :carteiraPadrao.sync="model.carteiraPadrao" :trazCarteira="true" :label="$t('PACIENTECADASTRO.CONVPADRAO')" table="Convenio"></searchIdName>
                            <div v-show="config.laboratorioVeterinario && model.animal"><searchIdName :id.sync="model.racaId" @item="racaEspecie" :label="$t('PACIENTECADASTRO.RACA')" table="Raca"></searchIdName></div>
                            <div v-show="config.laboratorioVeterinario && model.animal"><searchIdName :id.sync="model.especieId" :label="$t('PACIENTECADASTRO.ESPECIE')" table="Especie"></searchIdName></div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.NOMEMAE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.nomeDaMae">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <searchIdName :id.sync="model.responsavelId" :label="$t('PACIENTECADASTRO.RESPONSAVEL')" table="PacienteResponsavel">
                                <slot>
                                    <b-button icon-left="square-edit-outline"
                                              tag="router-link"
                                              target="_blank"
                                              :to="{ name: 'paciente', params: { id: model.responsavelId, return: true }}"
                                              :title="$t('GUIAATENDIMENTO.INFOPACIENTE')"></b-button>
                                    <b-button icon-left="plus-thick"
                                              tag="router-link"
                                              target="_blank"
                                              :to="{ name: 'paciente', query: { responsavel: true, retornarResponsavel:true }}"
                                              :title="$t('PACIENTECADASTRO.CADASTRORES')"></b-button>
                                </slot>
                            </searchIdName>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('AGENDA.OBSERVACOESGUIA')}}</label>
                                        <div class="control">
                                            <textarea class="textarea" maxlength="500" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent is-3">
                        <article class="tile is-child is-12">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('PACIENTECADASTRO.USUARIO')}}
                                </div>
                                <div v-if="!capturando">                                    
                                    <div class="panel-block">
                                        <figure class="image">
                                            <img :src="model.foto" ref="foto">
                                        </figure>
                                    </div>
                                </div>
                                <div v-else>                                    
                                    <div class="panel-block">
                                        <vue-web-cam ref="webcam"
                                                     :device-id="deviceId"
                                                     width="100%"
                                                     @cameras="onCameras" />
                                    </div>
                                    <div class="panel-block">
                                        <div class="field">
                                            <div class="select">
                                                <select v-model="camera">
                                                    <option>-- Select Device --</option>
                                                    <option v-for="device in devices"
                                                            :key="device.deviceId"
                                                            :value="device.deviceId">
                                                        {{ device.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-block tile is-12">                                    
                                        <b-button :class="capturando ? 'is-success  ' : 'is-link  '" 
                                                icon-left="camera"
                                                @click="onCapture" :title="$t('PACIENTECADASTRO.CAPTURAR')"
                                                expanded>
                                                {{$t('PACIENTECADASTRO.CAMERA')}}
                                        </b-button>

                                        <div class="file has-name tile is-6">                                            
                                            <label class="file-label tile is-6" style="width: 100%;">
                                                <input class="file-input" type="file" name="foto" ref="fileSele" @change="imageFileSelected">
                                                <div class="file-cta tile is-12" style="">            
                                                    <span class="file-label" >
                                                        <i class="mdi mdi-file"></i> Arquivo
                                                    </span>
                                                </div>  
                                            </label>                                          
                                        </div>                                                                         
                                                                                                           
                                </div>
  
                                <div v-if="model.id" class="panel-block">
                                    <b-field>
                                        <b-switch v-model="model.desativado" type="is-danger">
                                            {{$t('PACIENTECADASTRO.DESATIVADO')}}
                                        </b-switch>
                                    </b-field>
                                </div>
                                <div v-if="isInRole('usuarioweb-criar')" class="panel-block">
                                    <div class="field full-width">
                                        <b-switch v-model="model.criaUsuario" v-if="!model.possuiUsuario">
                                            {{$t('PACIENTECADASTRO.CRIARUSUARIO')}}
                                        </b-switch>
                                        <b-button v-if="model.possuiUsuario"
                                                  class="button is-danger "
                                                  @click="deletarUsuarioPaciente(model.id)"
                                                  expanded>
                                            {{$t('PACIENTECADASTRO.CANCELARUSUARIO')}}
                                        </b-button>

                                    </div>
                                </div>
                            </article>

                            <article v-if="model.id" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('PACIENTECADASTRO.AGENDAMENTOS')}}
                                </div>
                                <div v-for="(agendamento, index) in agendamentos" v-bind:key="index">
                                    <a @click="abrirGuia(agendamento)" class="panel-block">
                                        <span class="panel-icon block">
                                            <b-icon icon="book-open-page-variant" :style="agendamentoStyle(agendamento.tipoAgendamentoCor)" size="is-small"></b-icon>
                                        </span>
                                        <p v-if="agendamento.exameNome">{{ agendamento.dataHora }} - {{ agendamento.exameNome }}</p>
                                        <p v-else>{{ agendamento.dataHora }}</p>
                                    </a>
                                </div>
                                <div class="panel-heading has-text-centered">
                                    <b-button @click="abrirVerMais()" class="button is-info">Ver mais</b-button>
                                </div>
                            </article>

                            <article v-if="model.id" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('PACIENTECADASTRO.GUIAS')}}
                                </div>
                                <div class="panel-block is-justified-between tile is-12 btn-guias">
                                    
                                    <b-button class="tile is-4 " @click="criarGuia = true; onSubmit()">Criar</b-button>
                                    <router-link :to="{ name: 'guia-list', query: { pacienteId: model.id }, params: { agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: convenioIdAgendar }}" class="button is-link tile is-4 ">
                                        {{$t('PACIENTECADASTRO.LISTAR')}}
                                    </router-link>
                                    <a :href="'/Rel/PlanilhaDeResultados?pacienteId=' + model.id" class="button is-info tile is-4 ">Resultados</a>
                                </div>
                            </article>

                            <historico-alteracoes :tabela="'Paciente'" :chave="model.id"></historico-alteracoes>

                            <article v-if="model.id" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('PACIENTECADASTRO.DOC')}}
                                </div>
                                <p v-if="carregando" class="panel-tabs">
                                    <b-loading :active.sync="carregando"></b-loading>
                                </p>
                                <div v-if="!arquivosCarregados" class="panel-block">
                                    <b-button expanded @click="buscarHistoricoArquivos">Listar...</b-button>
                                </div>
                                <a class="panel-block" v-for="(item, index) in historicoArquivos" v-bind:key="index" @click="buscarArquivo(index, item.extensao);">
                                    <span class="panel-icon">
                                        <i class="fas fa-book" aria-hidden="true"></i>
                                    </span>
                                    <pre :id="'arquivo_' + index" style="display:none;">{{item.arquivo64}}</pre>
                                    {{item.nome}}
                                </a>
                                <p v-if="arquivosCarregados && (historicoArquivos == null || historicoArquivos.length == 0) " class="panel-block">
                                    {{$t('PACIENTECADASTRO.NENHUMDOC')}}
                                </p>
                            </article>
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    www.resultados.com.br
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.publicaResultadosNaInternet">
                                            {{$t('PACIENTECADASTRO.PUBRESULTADOS')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <p class="control has-icons-left">
                                    <input class="input" type="text" :placeholder="$t('PACIENTECADASTRO.CHAVE')" v-model="model.senha">
                                    <span class="icon is-left">
                                        <i class="fas fa-key" aria-hidden="true"></i>
                                    </span>
                                </p>
                            </article>
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('PACIENTECADASTRO.INDICACAO')}}
                                </div>
                                <div class="panel-block">
                                    <b-field>
                                        <b-select v-model="model.indicacao">
                                            <option v-for="opcaoIndicacao in opcoesIndicacao"
                                                    :value="opcaoIndicacao"
                                                    :key="opcaoIndicacao">
                                                {{ opcaoIndicacao }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </article>
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('PACIENTECADASTRO.CODINTEG')}}
                                </div>
                                <div class="panel-block">

                                    <div class="b-table tile is-12">                                    
                                        <div class="table-wrapper has-mobile-cards tile is-12">
                                            <table class="table is-striped is-hoverable">
                                                <thead>
                                                    <tr>                                                
                                                    <th class="">
                                                        <div class="th-wrap">
                                                            {{$t('PACIENTECADASTRO.TIPO')}}
                                                        </div>
                                                    </th>
                                                    <th class="">
                                                        <div class="th-wrap">
                                                            {{$t('PACIENTECADASTRO.CODIGO')}} 
                                                        </div>
                                                    </th>                                                
                                                    </tr>                                   
                                                </thead>
                                                <tbody>

                                                <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                    <td>
                                                        <b-select :placeholder="$t('PACIENTECADASTRO.INTEG')" v-model="item.integradorId" class="adicionar-codigo-integracao">
                                                            <option v-for="option in integradores"
                                                                    :value="option.id"
                                                                    :key="option.id">
                                                                {{ option.nome }}
                                                            </option>
                                                        </b-select>
                                                    </td>
                                                    <td>
                                                        <input class="input adicionar-codigo-integracao" type="text" v-model="item.codigo" >
                                                    </td>
                                                </tr>

                                                </tbody>
                                                <!---->
                                            </table>
                                        </div>

                                    </div>

                       
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                        {{$t('PACIENTECADASTRO.ADDCODIGO')}}
                                    </button>
                                </div>
                            </article>
                            <article class="panel">
                                <div v-if="isInRole('guia-integradores-consultar')" class="panel-heading has-text-centered">
                                    <div v-if="integradores.some(x => x.versao == 12)">
                                        <div class="panel">
                                            <p class="panel-heading background-2">
                                                {{$t('PACIENTECADASTRO.GUIAOPERADORA')}}
                                            </p>
                                            <div class="panel-block is-justified-between">
                                                <b-input v-model="operadoraGuia"
                                                         name="operadoraGuia"
                                                         :label="$t('PACIENTECADASTRO.GUIAOP')"
                                                         icon="magnify"></b-input>
                                            </div>
                                            <div class="panel-heading has-text-centered">
                                                <b-button type="is-primary"
                                                          @click="buscarGuiaOperadora()"
                                                          :loading="salvandoIntegrador">
                                                    {{$t('PACIENTECADASTRO.BUSCA')}}
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('paciente-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('PACIENTECADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('PACIENTECADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style scoped >

    @import '../../assets/css/views/cadastro/paciente.css';

</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import modalEnviarSms from '@/components/modalEnviarSms.vue'
    import moment from 'moment'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: this.$route.params.nome ?? null,
                    sexo: null,
                    dataDeNascimento: null,
                    cep: null,
                    endereco: null,
                    bairro: null,
                    cidadeId: null,
                    peso: null,
                    altura: null,

                    inscricao: null,
                    inscricaodv: null,
                    validade: null,
                    plano: null,
                    nomeDoTitular: null,
                    rg: null,
                    cpf: null,
                    cartaoNacionalDeSaude: null,
                    convenioPadraoId: null,
                    carteiraPadrao: null,
                    racaId: null,
                    racaEspecieId: null,
                    especieId: null,
                    email: null,
                    nomedamae: null,
                    responsavelId: null,
                    observacoes: null,

                    codigosIntegrador: [],

                    criaUsuario: false,
                    indicacao: null,
                    publicaResultadosNaInternet: false,
                    animal: false,
                    foto: null,
                    unidadeDeCadastroId: this.unidadeId,
                    unidadeIdade: null,
                    guiaOperadora: null,
                },
                validaCarteira: true,
                dataAtual: new Date(new Date().setHours(0,0,0,0)),
                agendamentos: null,
                tipoAgendamentoIdFiltro: null,
                nenhumRaca: null,
                nenhumEspecie: null,
                especiesAtivos:  [],
                capturando: false,
                camera: null,
                deviceId: null,
                devices: [],
                carregando: true,
                historicoArquivos: [],
                operadoraGuia: null,
                salvandoDados: false,
                salvandoIntegrador: false,
                nomeSocial: false,
                fotoAlterada: false,
                criarGuia: Boolean(this.$route.params.criarGuia) ?? false,
                responsavel: Boolean(this.$route.query.responsavel) ?? false,                            
                retornarResponsavel: Boolean(this.$route.query.retornarResponsavel) ?? false,
                retornarPaciente: Boolean(this.$route.query.retornarPaciente) ?? false,
                agendamentoDashboard: this.$route.params.agendamentoDashboard,
                convenioIdAgendar: this.$route.params.convenioIdAgendar,
                agendamento: this.$route.params.agendamento ? this.$route.params.agendamento : null,                
                observacoesInspecao: this.$route.params.observacoesInspecao ? this.$route.params.observacoesInspecao : null,                
                arquivosCarregados: false,

                opcoesIndicacao: [
                    'Google',
                    'Facebook',
                    'Instagram',
                    'Panfleto',
                    'Revista',
                    'Solicitante',
                    'Amigo',
                    'Outros'
                ]
            };
        },
        components: {
            buscaCepEndereco,
            titlebar,
            searchIdName,
            historicoAlteracoes
        },     
            
        created() {


            if (this.$route.query.tipoAgendamentoId)
                this.tipoAgendamentoIdFiltro = this.$route.query.tipoAgendamentoId;
            
            if(this.status == null)
                this.status = 2

            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/paciente?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;

                        if(this.model.dataDeNascimento){
                            this.model.dataDeNascimento = new Date(this.model.dataDeNascimento)
                        }
                        if (this.model.validade) {
                            this.model.validade = new Date(this.model.validade)
                        }
                        if (this.model?.foto){
                            if (this.model.foto.indexOf('data:image/') < 0){
                                this.model.foto = ` data:image/*;charset=utf-8;base64,${this.model.foto}`;
                            }
                        }

                        this.nomeSocial = this.model.nomeSocial != null
                        
                        this.model.criaUsuario = this.model.usuario != null

                        this.loadData();

                        this.carregando = false
                    })
            }
            else {
                this.carregando = false;
            }
            this.model.publicaResultadosNaInternet = this.config.resultadoNaInternetAuto;
            this.model.criaUsuario = this.config.resultadoNaInternetAuto;

            document.addEventListener('visibilitychange', this.verificaRetornarResponsavel, false);           
        },
        computed: {
            titleStack() {
                return [
                    this.$t('PACIENTECADASTRO.MANUTENCAO'),
                    this.$t('SISTEMA.PACIENTE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            podeEnviarSms() {
                return this.model.celular && this.model.celular.length >= 14
            },
            iconeEnviarSms() {
                return this.podeEnviarSms
                    ? "cellphone-message"
                    : null
            },
            idade() {
                if(this.model.dataDeNascimento == null){
                    return "";
                }

                return this.calcularIdade();
            },
            device() {
                return this.devices.find(n => n.deviceId === this.deviceId);
            },
            convenioChanged() {
                return this.model.convenioPadraoId
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
        },
        watch: {
            camera: function (id) {
                this.deviceId = id;
            },
            devices: function () {
                // Once we have a list select the first one
                const [first] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            },
            convenioChanged: function(value) {
                this.validaCarteiraPadrao();
            }
        },
        methods: {
            racaEspecie(item){
                if(item != null && item.data != null && item.data.especieId != null){
                    this.racaEspecieId = item.data.especieId;
                }
            },
             verificaRetornarResponsavel() {                   
                if(window.localStorage.getItem('retornarResponsavel') > 0 && !this.retornarResponsavel){                    
                    this.model.responsavelId = window.localStorage.getItem('retornarResponsavel');
                    setTimeout(() => {
                        localStorage.retornarResponsavel = null;
                    }, 1000);
                    
                }                
            },            
            buscarGuiaOperadora() {
                this.salvandoIntegrador = true;

                if(this.model.convenioPadraoId == 0 || this.model.convenioPadraoId == null || this.model.convenioPadraoId == '' || this.model.convenioPadraoId == undefined){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.CONVOBRIGATORIO'),
                        type: 'is-danger',
                        queue: false
                    });
                    this.salvandoIntegrador = false;
                    return;
                }

                if(this.operadoraGuia == 0 || this.operadoraGuia == null || this.operadoraGuia == '' || this.operadoraGuia == undefined){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.NUMGUIA'),
                        type: 'is-danger',
                        queue: false
                    });
                    this.salvandoIntegrador = false;
                    return;
                }

                this.model.guiaOperadora = this.operadoraGuia;

                this.onSubmit();
            }, 
            calcularIdade(){
                if(this.model.dataDeNascimento > new Date()){
                    this.model.dataDeNascimento = null;
                    this.idade = null;

                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.NASCIMENTOINVALIDO'),
                            type: 'is-danger',
                            queue: false
                        })

                    return;
                }

                const today = new Date();
                let m = today.getMonth() - this.model.dataDeNascimento.getMonth();
                                
                const dateAge = new Date(Date.now() - this.model.dataDeNascimento.getTime()); 
                const age = Math.abs(dateAge.getUTCFullYear() - 1970);
                                
                if(age > 200){
                    this.model.dataDeNascimento = null;
                    this.idade = null;

                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.NASCIMENTOINVALIDO'),
                            type: 'is-danger',
                            queue: false
                        })

                    return;
                }

                if (age >= 1) {
                    if (age == 1) {
                        return ~~(age) + " ano";
                    } else {
                        return ~~(age) + " anos";
                    }
                } else {
                    if ((age == 0) && (m < 0)) {
                        m += 12;
                    }

                    if (today.getDate() < this.model.dataDeNascimento.getDate()) {
                        m--;
                    }

                    if (m > 0) {
                        if (m == 1) {
                            return ~~(m) + " mês";
                        } else {
                            return ~~(m) + " meses";
                        }
                    } else {
                        const d = (new Date(today.getFullYear(), today.getMonth(), today.getDate()) - this.model.dataDeNascimento) / 86400000;

                        if (d > 0) {
                            if (d == 1) {
                                return ~~(d) + " dia";
                            } else {
                                return ~~(d) + " dias";
                            }
                        } else {
                            return "";
                        }
                    }
                }
            },
            pacienteMenor(){

                if(this.model.dataDeNascimento == null){
                    return false;
                }

                const idade = parseInt(this.calcularIdade().replace(/\D/g,''));                    
                
                const possuiResponsavel = (this.model.responsavelId != 0 && 
                this.model.responsavelId != null && this.model.responsavelId != '' && this.model.responsavelId != undefined);
                

                return (idade<18 && possuiResponsavel);

            },
            

            validaPacienteMenor(){

                if(this.model.dataDeNascimento == null){
                return false;
                }

            const idade = parseInt(this.calcularIdade().replace(/\D/g,''));                    

            return (idade<18);

            },

            alterouIdade(event) {
                const id = event.target.value.replace(",", ".");

                if (id < 1) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.IDADEINVALIDA'),
                        type: 'is-danger',
                        queue: false
                    })

                    return;
                }

                if (this.model.unidadeIdade == "D") {
                    const dataNascimento = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    const quantidade = (id * 24 * 60 * 60 * 1000);
                    this.model.dataDeNascimento = new Date(dataNascimento.valueOf() - quantidade);
                }
                else if (this.model.unidadeIdade == "M") {
                    const dataNascimento = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    const quantidade = (id * 31 * 24 * 60 * 60 * 1000);
                    this.model.dataDeNascimento = new Date(dataNascimento.valueOf() - quantidade);
                }
                else {
                    this.model.dataDeNascimento = new Date(new Date().getFullYear() - id, new Date().getMonth(), new Date().getDate()-1);
                }
            },
            validaCarteiraPadrao() {   
                console.log('editou')
                if(this.model.convenioPadraoId > 0 && this.model.convenioPadraoId != null && this.model.carteiraPadrao != null && this.model.carteiraPadrao != ""){
                    const carteiraPadraoInicial = [];

                    const padroesDeCarteira = this.model.carteiraPadrao.split(";");
                    padroesDeCarteira.forEach(x => carteiraPadraoInicial.push(x.replace(/\D/g, '')));

                    const possuiCarteira = carteiraPadraoInicial.filter(x => this.model.inscricao.startsWith(x) && x != '');
                    
                    const lenghtCarteira = padroesDeCarteira.filter(x => x.startsWith(possuiCarteira[0])); 

                    if(possuiCarteira.length > 0 && lenghtCarteira.length > null ) {
                        this.validaCarteira =  possuiCarteira[0].length > 0 && lenghtCarteira[0].length === this.model.inscricao.length
                    }
                    else {

                        this.validaCarteira = false;
                    }

                }else {
                    this.validaCarteira = true;
                }
            },
            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'paciente' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.EMAILMSG') + res.data.email + this.$t('PACIENTECADASTRO.EMUSO') + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },
            imageFileSelected: function(e) {
                const { name, type, size } = e.target.files[0];
                let imageData = null;
                const ref = this.$refs.foto;
                if (FileReader && size) { 
                    const fr = new FileReader();
                    fr.onload = () => {
                        imageData = fr.result;
                        this.model.foto = imageData;
                        this.fotoAlterada = true;
                        ref.src = imageData;
                    }
                    fr.readAsDataURL(e.target.files[0]);
                }
            },
          
            onSubmit() {
                localStorage.animal = this.model.animal
                this.validaCarteiraPadrao();


                if(this.model.convenioPadraoId && this.model.carteiraPadrao) {
                    if(this.model.inscricao == null || this.model.inscricao == "") {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.INSCRICAOOBRIGATORIA'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
                }

                if(this.validaCarteira === false){
                    return;
                }

                if(this.model.animal){            
                  //raça obrigatório e responsável obrigatório                    
                    if(this.model.racaId == 0 || this.model.racaId == null || this.model.racaId == '' || this.model.racaId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.RACAOBGR'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
               
                    if(this.model.especieId == 0 || this.model.especieId == null || this.model.especieId == '' || this.model.especieId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.ESPECIEOBGR'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }else {

                        let racaEspecieCompativel = true;

                        if(this.racaEspecieId != null && this.racaEspecieId != undefined){
                            if(this.racaEspecieId !== this.model.especieId){
                                racaEspecieCompativel = false;
                            }
                        }
      
                        if(!racaEspecieCompativel){

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.ESPECIEINVALIDA'),
                                type: 'is-danger',
                                queue: false
                            });                               
                            return;
                        }                                                             
                    }

                    if(this.config.responsavelObrigatorioVet){
                        if(this.model.responsavelId == 0 || this.model.responsavelId == null || this.model.responsavelId == '' || this.model.responsavelId == undefined){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.RESPOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return;
                        }
                    }
                }
                else {
                    
                    if(this.config.cpfObrigatorio && !this.pacienteMenor()){
                                                
                        if ((this.model.cpf == 0 || this.model.cpf == null || this.model.cpf == '' || this.model.cpf == undefined) &&
                            (this.model.passaporte == 0 || this.model.passaporte == null || this.model.passaporte == '' || this.model.passaporte == undefined)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.CPFOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return;
                        }
                    }

                    if(this.config.responsavelObrigatorioParaMenorDeIdade){
                        const possuiResponsavel = (this.model.responsavelId != 0 && 
                        this.model.responsavelId != null && this.model.responsavelId != '' && this.model.responsavelId != undefined);
            
                        if(this.validaPacienteMenor() && !possuiResponsavel){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.RESPONSAVELOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return;
                        }
                    }


                    if(this.config.enderecoObrigatorio){
                        if(this.model.endereco == 0 || this.model.endereco == null || this.model.endereco == '' || this.model.endereco == undefined){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.ENDOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return;
                        }
                    }

                    if(this.config.emailObrigatorio){
                        if(this.model.email == 0 || this.model.email == null || this.model.email == '' || this.model.email == undefined){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.EMAILOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return;
                        }
                    }

                    if(this.config.celularObrigatorio){
                        if(this.model.celular == 0 || this.model.celular == null || this.model.celular == '' || this.model.celular == undefined){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.CELOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return;
                        }
                    }
                }

                if(this.config.convenioPadraoObrigatorioPaciente){
                    if(this.model.convenioPadraoId == 0 || this.model.convenioPadraoId == null || this.model.convenioPadraoId == '' || this.model.convenioPadraoId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.CONVOBRIGATORIO'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
                }

                if (!this.nomeSocial && this.model.nomeSocial != null) {
                    this.model.nomeSocial = null
                }
                
                if(this.model.criaUsuario && this.model.email == null && !this.config.permiteCriarUsuarioParaPacienteSemEmail){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.FALHAUSUARIO'),
                        type: 'is-danger',
                        queue: false
                    });
                    this.$refs.email.focus()
                    return;
                }
                
                if(this.config.cpfUnico && this.model.cpf != 0 && this.model.cpf != null && this.model.cpf != '' && this.model.cpf != undefined){
                    this.salvandoDados = true;

                    if(!this.model.id){
                        this.model.id = 0;
                    }

                    this.$http.get(`/api/manutencao/CheckCPF?cpf=${this.model.cpf}&id=${this.model.id}`)
                        .then(res => {
                            this.salvandoDados = false;
                            if(res.data > 0){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('PACIENTECADASTRO.CPFUSO'),
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                            else {
                                this.salvarPaciente();
                            }
                        })
                        .catch(err => {
                            this.salvandoDados = false;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.ERROSALVARPACIENTE') + err.body.title,
                                type: 'is-danger',
                                queue: false
                            })
                        })
                }
                else {
                    this.salvarPaciente();
                }

            },
            salvarPaciente(){
                this.salvandoDados = true;


                this.$http.post('/api/manutencao/paciente', this.model)
                    .then((res) => {

                        if(this.agendamento && this.agendamento.length>0) {

                            this.agendamento.forEach(a => {

                                const params = [];

                                params.push(`pacienteId=${res.data.id}`)
                                params.push(`id=${a.evento.id}`); 

                                if(this.observacoesInspecao)
                                    params.push(`observacao=${this.observacoesInspecao}`);
                                
                                const url = `/api/agenda/agendaEditar?${params.join('&')}`;
                                
                                this.$http.post(url)
                                .then(res => res.data)
                                .then(data => {
                                    if (data.mensagem != null && data.mensagem != "") {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: data.mensagem,
                                        type: 'is-danger',
                                        queue: false
                                    })
                                    }                                    
                                });   
                                            

                            });

                        }

                        if(this.retornarResponsavel) 
                        {                                                        
                            if(res.data.id > 0){
                                window.localStorage.setItem('retornarResponsavel', res.data.id);
                            }                            
                            window.close();
                        }
                        if(this.retornarPaciente) {
                            localStorage.retornarPaciente = res.data.id;
                            window.close();
                        }
                               
                        else if (this.criarGuia) {
                            if(!this.model.desativado){
                                    this.$router.push({
                                    name: 'novaguia', params: {
                                        pacienteId: res.data.id,
                                        agendar: this.agendamento,                                        
                                        agendamentoDashboard: this.agendamentoDashboard,
                                        convenioIdAgendar: this.convenioIdAgendar,
                                        observacoesInspecao: this.observacoesInspecao
                                    }
                                })
                            }else{
                                this.$router.back();
                            }
                        } else if (this.agendamento && this.agendamento.length>0 && this.config.naoGerarGuiaAposInspecao) {
                            this.$router.push({
                                name: 'inspecao', 
                                query: { unidadeId: this.unidadeId }
                            });
                        } else {
                            if(res.data.mensagem != null){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.data.mensagem,
                                    type: 'is-info',
                                    queue: false
                                })
                            }
                            this.$router.back()
                        }
                        this.salvandoDados = false;
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        console.error(err);
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.ERROPACIENTE') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },

            onCapture() {
                if (this.capturando) {
                    this.model.foto = this.$refs.webcam.capture();
                    setTimeout(() => {
                        this.$refs.webcam.stop();
                        this.capturando = false;
                    }, 500);
                } else {
                    this.capturando = true;
                    setTimeout(() => { this.$refs.webcam.start() }, 500);
                }
            },
            onCameras(cameras) {
                this.devices = cameras;
                
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;                
            },
            buscarArquivo(index, extensao) {
                const base64Data = document.getElementById("arquivo_" + index).innerText;

                const sliceSize = 512;
                const byteCharacters = window.atob(base64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                
                const blob = new Blob(byteArrays, {
                    type: 'application/' + extensao
                });

                const blobURL = URL.createObjectURL(blob);
                //window.open(blobURL);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = blobURL;
                link.download = "Documento." + extensao;
                link.click();
                window.URL.revokeObjectURL(blobURL);
                link.remove();
            },

            abrirModalSms() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalEnviarSms,
                    props: {
                        numero: this.model.celular,
                        id: this.model.id
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            deletarUsuarioPaciente(id) {
                this.$http.get(`/api/manutencao/deletarUsuarioPaciente?pacienteId=${id}`).then(res => {
                      if(res.data.ok){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.messagem,
                                type: 'is-success',
                                queue: false
                            })                           
                            this.$router.back();
                       }else{
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.messagem,
                                type: 'is-danger',
                                queue: false
                            })
                       }
                })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.ERROPACIENTE') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })

            },
          
            buscarHistoricoArquivos() {
                this.carregando = true
                this.$http.get('/api/atendimento/historicoArquivosPaciente?pacienteId=' + this.$route.params.id)
                    .then(res => {
                        this.carregando = false
                        this.historicoArquivos = res.data
                        this.arquivosCarregados = true
                    })
                    .catch(err => {
                        this.carregando = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PACIENTECADASTRO.ERROHIST') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },

            loadData() {               
                const params = [];

                if (this.dataAtual != null)
                    params.push(`dataInicial=${moment(this.dataAtual).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.tipoAgendamentoIdFiltro) 
                    params.push(`tipoAgendamentoId=${this.tipoAgendamentoIdFiltro}`);
                if (this.status != null) 
                    params.push(`status=${this.status}`);
                if(this.model.id)
                    params.push(`pacienteID=${this.model.id}`);
                params.push(`unidadeId=${this.unidadeId}`);

                try {
                    this.$http.get(`/api/agenda/RelacaoPaciente?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.agendamentos = data?.map( (d) => {
                                    return {
                                        id: d.id,
                                        guiaId: d.guiaId,
                                        pacienteId: d.pacienteId,
                                        exameNome: d.exameNome,
                                        tipoAgendamentoId: d.tipoAgendamentoId,
                                        tipoAgendamentoCor: d.tipoAgendamentoCor,
                                        dataHora: moment(d.dataHora).format('DD/MM/YYYY HH:mm:ss')
                                    }
                                });
                            }
                        }).catch(e => {
                            throw e;
                        }).finally(() => {
                            this.$forceUpdate();
                            this.carregando = false;
                        });
                } catch (e) {
                    console.error(e);
                    this.carregando = false;
                    throw e;
                }
                
            },

            abrirGuia(agendamento) {                
                if(agendamento.guiaId != 0)
                    this.$router.push({ path: `/atendimento/guia/${agendamento.guiaId}` })
                else{
                    this.$router.push({
                        name: 'novaguia', 
                        params: {
                            pacienteId: agendamento.pacienteId,
                            agendamento: agendamento.id,
                            agendamentoDashboard: this.agendamentoDashboard,                            
                            convenioIdAgendar: this.convenioIdAgendar,
                        }
                    })   
                }
            },
            
            agendamentoStyle(tipoAgendamentoCor){
                return {
                    color: `#${tipoAgendamentoCor}`
                }
            },

            abrirVerMais(){
                const params = {};
                params.pacienteId = this.model.id;

                this.$router.push({ 
                    name: 'agenda-relacao',
                    params: params
                 })
            }

        }
    }
</script>