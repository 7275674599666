
<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>


        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">

                    <div class="columns is-12">
                        <div class="column is-4">
                            <searchIdName v-show="(!examesLaboratorial)" table="Convenio" :label="$t('SISTEMA.CONVENIO')"  :id.sync="convenioIdAgendar" @item="escolheuConvenio"   >
                            </searchIdName>
                        </div>
                        <div class="column is-4">
                            <searchIdName  v-show="(this.agendas && this.agendas.length >0)"  table="FuncionarioComCrm" :label="$t('AGENDA.EXECUTANTE')" :id.sync="executanteIdAgendar" @item="escolheuExecutante" >
                            </searchIdName>                                                       
                        </div>                        


                        <div class="column is-2">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-tooltip :label="$t('AGENDA.MENSAGEMEXAMELABORATORIAL')" multilined>
                                    <b-switch v-model="examesLaboratorial" native-value="false">                                        
                                         {{$t('AGENDA.EXAMELABORATORIAL')}}
                                    </b-switch>                        
                                </b-tooltip>
                        </div>


                            
                        <div class="column is-2 has-text-centered">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-button type="is-primary"  icon-left="calendar" @click="confirmarAlteracaoDataAgendas()">                                    
                                    {{$t('AGENDA.IRPARADATA')}}
                                </b-button>                                                                                  
                        </div>

                         
                    </div>
                        <div v-show="(!examesLaboratorial)" class="columns">
                                                          
                            <div class="column is-12">
                                <nav class="panel">
                                    <p class="panel-heading">
                                        {{$t('AGENDA.AGENDAREXAMES')}}
                                        <button v-show="listaExames && listaExames.length>0"  type="button" class="delete is-pulled-right" aria-label="delete" @click="listaExames=[]; procurarExameTexto=null;"></button>
                                    </p>
                                    <div class="panel-block" v-show="examesSelecionados && examesSelecionados.length>0">
                                        <b-taginput
                                            v-model="examesSelecionados"                                            
                                            autocomplete
                                            :allow-new="false"                                                                                                                                                      
                                            field="nome"
                                            icon="label" >                                                          
                                        </b-taginput>                                        
                                    </div>
                                    <div class="panel-block">
                                        <b-input v-model="procurarExameTexto"
                                                 icon="magnify"
                                                 @input="inputListaExames"
                                                 @keydown.native.esc="listaExames=[]; procurarExameTexto=null;"
                                                 @keydown.native.down="incluindoExameIndex++"
                                                 @keydown.native.up="incluindoExameIndex--"
                                                 @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])"
                                                 placeholder="Pesquisar Exames"
                                                 ref="procurarExameTextoInput"></b-input>

                                        <b-dropdown aria-role="list"
                                                    position="is-bottom-left"
                                                    v-model="procurarExamePor"
                                                    @input="$refs.procurarExameTextoInput.focus()">

                                            <button class="button" slot="trigger" slot-scope="{ active }">
                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                            </button>
                                            <b-dropdown-item aria-role="menu-item" custom>
                                                <small>{{$t('GUIAATENDIMENTO.PROCURARPOR')}}</small>
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="apelido">
                                                {{$t('GUIAATENDIMENTO.APELIDO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Nome">
                                                {{$t('GUIAATENDIMENTO.NOME')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Codigo Externo">
                                                {{$t('GUIAATENDIMENTO.CODIGOEXTERNO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Id">
                                                {{$t('GUIAATENDIMENTO.IDENTIFICACAO')}}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>                                    
                                    <template v-for="(exame, index) in listaExames" >
                                        <template v-if="index == 0 || exame.quantidadeDeAmostras <= 1 || listaExames[index - 1].id != exame.id">
                                            <span class="panel-block columns is-multiline" style="border: 1px solid red; background-image: none !important; margin: 2px 0px;" 
                                                v-if="exame.bloqueado" :key="index">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                    <p v-if="exame.bloqueado">
                                                        <br />
                                                        <span class="tag">{{$t('GUIAATENDIMENTO.BLOQUEADO')}}{{exame.motivoBloqueio}}</span>
                                                    </p>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">
                                                        <span class="is-size-7">{{exame.codigo}}</span>
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">Jejum: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </span>
                                            <a class="panel-block columns is-multiline" v-else :key="index" @click="incluirExame(exame)" v-bind:class="{ 'has-background-primary': incluindoExameIndex == index }">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">                                                
                                                    <span class="is-size-7">{{exame.codigo}}</span>
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">                                                        
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">Jejum: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </a>
                                        </template>
                                    </template>
                                </nav>
                            </div>
                 
                        </div>    
                        <div v-show="(!examesLaboratorial)" class="columns">                        
                            <div class="column is-8">
                                <label class="label">{{$t('PACIENTECADASTRO.OBSERVACOES')}}</label>
                                <div class="control">
                                    <textarea class="textarea" rows="3"  maxlength="500" v-model="observacoesInspecao"></textarea>
                                </div>                                
                            </div>  
                            <div class="column is-1 has-text-centered" v-if="(this.agendas && this.agendas.length >0)">
                                    <label class="label" style="visibility: hidden;">Label Filtro</label>
                                    <b-button type="is-success" @click="salvarAgendamentos()" icon-left="check-circle"> 
                                        {{$t('CONFIG.SALVAR')}}
                                    </b-button>                                                   
                            </div>                        
                            <div class="column is-1 has-text-centered" v-if="(this.agendas && this.agendas.length >0 && this.eventosSalvar.length >0 )">
                                    <label class="label" style="visibility: hidden;">Label Filtro</label>
                                    <b-button type="is-danger" @click="cancelarAgendamentos()" icon-left="close-circle"> 
                                        {{$t('SISTEMA.CANCELAR')}}
                                    </b-button>                                                                                                           
                            </div>                                             
                        </div>                      

                    <div class="columns is-multiline is-mobile is-gapless">
                        <b-checkbox-button class="column is-2-desktop is-half-mobile" v-for="(tipo) in tiposAgendamentoAtivos" v-bind:key="tipo.id" 
                                v-model="filtrosAgendamentoId"
                                :native-value="tipo.id">
                            <b-icon icon="rectangle"  v-if="tipo.cor" :style="tipoAgendamentoStyle(tipo)"></b-icon>           
                            <span class="text-nowrap">{{tipo.nome}}</span>
                        </b-checkbox-button>
                    </div>
                </article>

                <div v-show="!isLoading" class="columns whitebox"> 
                    <div style="border-right: 3px #ced8ce solid;" class="column is-fullwidth-mobile p-0" v-for="(agenda, index) in agendas" v-bind:key="index">
                        <b-collapse  aria-id="agendaDias">
                            <template slot="header">
                                <span>  {{$t('AGENDA.DIARIO')}}  </span>
                            </template>
                            <div class="columns is-multiline whitebox" v-show="!isLoading">                                                                               
                                <div class="column is-12 has-text-centered whitebox" > 
                                        <b-button class="is-pulled-left "  icon-left="chevron-triple-left" @click="proximoDiaVago(-1,agenda);" ></b-button>                                                                            
                                        <b-button class="is-pulled-left"  icon-left="chevron-left" @click="proximoDia(-1,agenda);" ></b-button>        
                                        
                                        <a href="#" class="has-text-grey" title="Alterar Data da Agenda" @click="confirmarAlteracaoDataAgendas(agenda);">
                                            <b-icon icon="calendar" size="is-small"></b-icon>
                                        </a>                                                                      
                                        <span class="is-size is-inline-block mt-2"><b>{{$moment(agenda.dataDiaFiltro).format('DD/MM/YYYY')}} - {{ getDayOfWeek(agenda.dataDiaFiltro)}}</b></span>                                     
                                        
                                        <b-button class="is-pulled-right "  icon-left="chevron-triple-right" @click="proximoDiaVago(1,agenda);" ></b-button>                                    
                                        <b-button class="is-pulled-right" icon-left="chevron-right"  @click="proximoDia(1,agenda);" ></b-button> 
                                        <span v-if="(!examesLaboratorial)">
                                            <b-button v-if="isInRole('agenda-incluir-encaixe')"  class="is-pulled-right mr-4"  icon-left="arrow-collapse-vertical" @click="confirmarEncaixe(agenda)"  :title="$t('AGENDA.ENCAIXE')" ></b-button>                                                                                                                                                           
                                        </span>
                                        <span v-else>
                                            <b-button v-if="isInRole('agenda-incluir-encaixe')"  class="is-pulled-right mr-4"  icon-left="arrow-collapse-vertical"  :title="$t('AGENDA.ENCAIXE')" tag="router-link" :to="{ name: 'agendamento',params: {permiteAlterar:true, tipoAgendamentoId: agenda.tipoAgendamentoId, encaixe:true} }" ></b-button>                                                                                                                                                           
                                        </span>

                                        
                                </div>                                 
                                <inspecao-item v-if="agenda.eventosDia.length>0" :eventos="agenda.eventosDia" @incluirNovoHorarioDia="incluirNovoHorarioDia" 
                                     @loadDataDia="loadDataDia"  @verificarAgenda="verificarAgenda"  :exibirCheckBox="(agenda.possuiEquipamentoVinculado && agendas.length >0 )"></inspecao-item>                                  
                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div>                                                              
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </div>            
        </div>
       
    </section>
</template>

<style lang="scss" scoped>
    
    .text-nowrap{
        text-overflow: ellipsis;
        white-space: nowrap;        
        overflow: hidden;                
    }
  

</style>

<script>
    import {  mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue';
    import moment from 'moment';    
    import inspecaoItem from '@/components/atendimento/inspecao-item.vue';
    import searchIdName from '@/components/searchIdName.vue';
    import modalAlterarDataAgendamento from '@/components/atendimento/modalAlterarDataAgendamento.vue'
    import modalAlterarDataEncaixe from '@/components/atendimento/modalAlterarDataEncaixe.vue'
    import debounce from 'lodash/debounce'        
 
    export default {
        components: {
            titlebar,
            inspecaoItem,
            searchIdName,
            
        },

        computed: {
            ...mapState([                
                'config',
                'unidadeId',
                'componentes'
            ]),  
            ...mapGetters([
                'isInRole'
            ]) ,                    
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('ATENDIMENTO.INSPECAO'),
                ]
            }
        },
        watch: {
            unidadeId: function(novaUnidade) {                 
                this.$route.query.unidadeId = novaUnidade;                
                this.$forceUpdate();
                this.loadDataDia();                                                
            },
            convenioIdAgendar: function(value) {                 
                if(!value || value==null){
                    this.listaExames = [];                     
                }                          
                this.procurarExameTexto = null;                     
            },
         
            filtrosAgendamentoId: function(value) {                
                if(value && value.length > 0){
                    this.loadDataDia();
                    
                }else{                    
                    this.agendas = [];
                    this.eventosSalvar = [];
                }                
            },            
            examesLaboratorial: function() {
                this.examesSelecionados = [];
                this.filtrosAgendamentoId = [];
                this.eventosSalvar = [];

                if(this.examesLaboratorial)            
                    this.loadTiposAgendamentos();                
                
            },    
            examesSelecionados: function() {                
                if(this.examesSelecionados.length > 0){
                    this.loadTiposAgendamentos();                    
                    
                }else{                    
                    this.tiposAgendamentoAtivos = [];                                    
                    this.eventosSalvar = [];   
                    this.filtrosAgendamentoId = [];                 
                }
                this.procurarExameTexto = null;
            },         

        },        
        data() {
            return {                                                     
                filtrosAgendamentoId: [],
                tiposAgendamentoAtivos: [],
                agendas: [],                         
                examesSelecionados: [],                
                isLoading: false,
                examesLaboratorial: false,
                eventosSalvar:[],          
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                convenioIdAgendar: null, 
                executanteIdAgendar:null, 
                convenioExameCodigoTipoAgendar: null,
                incluindoExameIndex: 0,
                procurarExamePor: localStorage.procurarExamePor ?? 'apelido',
                procurarExameTexto: '',
                mostraDetalhesExameId: null,       
                isExterno: false, 
                listaExames: null,  
                observacoesInspecao:null,
                contador: 1      
            }
        },
        created() {
            return true;
        },
        mounted() {        
            this.componentes.sidebarIsOpened = false;  

        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },        
        methods: {

            confirmarEncaixe(agenda){
                        
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataEncaixe,
                    events: {
                        encaixePaciente: this.encaixePaciente
                    },   
                    props: {
                        agenda: agenda,
                        desabilitarDia: true,
                        data: agenda.dataDiaFiltro
                    },                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            },    
            encaixePaciente(agenda,novaData){

                const novoEventoEncaixe = { ...agenda.eventosDia[0] };
                novoEventoEncaixe.id = 0;
                novoEventoEncaixe.observacao = null;
                novoEventoEncaixe.selecionado = true;
                novoEventoEncaixe.bloquearAgenda = false;
                novoEventoEncaixe.descricaoExame = "";
                novoEventoEncaixe.dataHora = moment(novaData).format('YYYY-MM-DDTHH:mm:ss');
                novoEventoEncaixe.data =  moment(novaData);                 
                novoEventoEncaixe.hour = moment(novaData).format('HH:mm');
                novoEventoEncaixe.encaixe = true;
                novoEventoEncaixe.funcionarioId = (this.funcionarioId) ? this.funcionarioId : null;
                novoEventoEncaixe.pacienteNome = null;
                novoEventoEncaixe.convenioNome = null;
                
                agenda.eventosDia.push(novoEventoEncaixe);                
                agenda.eventosDia = agenda.eventosDia.sort((a, b) => (a.dataHora > b.dataHora) ? 1 : -1);

                this.verificarAgenda(novoEventoEncaixe);
                this.$forceUpdate();        
                return true;
                
            },                     
            inputListaExames: debounce(function () {
                this.loadListaExames()
            }, 500),
            async atualizaEventosSalvar() {                    
                
                this.eventosSalvar.forEach(e => { 
                    
                    const agenda = this.agendas.filter(a=> a.tipoAgendamentoId == e.evento.tipoAgendamentoId)[0];                    
                    if(agenda){                        
                        
                        const evento = agenda.eventosDia.filter(a=> a.dataHora == e.evento.dataHora)[0];                        
                        if(evento){
                            evento.selecionado=true;
                            evento.bloquearAgenda = true;
                            evento.descricaoExame = e.evento.descricaoExame;                            
                        }                                                             
                    }
                });
                           
                if(this.eventosSalvar.length > 0) {                    
                    this.VerificarBloqueioAgendas();                    
                }                                           
                                    
                
            },            
            escolheuConvenio(val) {    
                this.convenioExameCodigoTipoAgendar = val.data.exameCodigoTipoId;    
            },
            escolheuExecutante(val) {    
                
                
                if(!val.nome)
                    this.executanteIdAgendar=null;

                this.agendas.forEach(a => {
                    this.carregarDataDia(a);    
                });                     
                
            },            
            mostraDetalhes(id, event) {
                event.stopPropagation();
                if (this.mostraDetalhesExameId == id) {
                    this.mostraDetalhesExameId = null;
                }
                else {
                    this.mostraDetalhesExameId = id;
                }
            },            
            loadListaExames() {
                if (this.convenioIdAgendar) {
                    const params = {
                        texto: this.procurarExameTexto,
                        campo: this.procurarExamePor,                        
                        convenioId: this.convenioIdAgendar,
                        exameCodigoTipoId: this.convenioExameCodigoTipoAgendar
                    }
                    this.$http.get('/api/atendimento/EditGuiaListaExames', { params })
                        .then(res => {
                            this.listaExames = res.data
                            this.incluindoExameIndex = 0
                            this.$refs.procurarExameTextoInput.focus()
                        })
                }else{
                    this.listaExames = [];
                }
            },            
            incluirExame(exame) {
                if(exame.bloqueado){
                    return;
                }

                const dataDeSolicitacao = new Date();
                let msgDiasRealizacao = "";

                exame.listDiasRealizacao?.forEach(dia => {
                    switch (dia) {
                        case 0:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.DOMINGO')}&nbsp;&nbsp;`;
                            break;
                        case 1:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SEGUNDA')}&nbsp;&nbsp;`;
                            break;
                        case 2:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.TERCA')}&nbsp;&nbsp;`;
                            break;
                        case 3:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.QUARTA')}&nbsp;&nbsp;`;
                            break;
                        case 4:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.QUINTA')}&nbsp;&nbsp;`;
                            break;
                        case 5:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SEXTA')}&nbsp;&nbsp;`;
                            break;
                        case 6:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SABADO')}&nbsp;&nbsp;`;
                            break;
                        default:
                            throw new Error('Index out of range');
                    }
                });

                const id = exame.id ? exame.id : exame.exameId;

                if(this.examesSelecionados.filter(x => x.exameId == id && x.exameDePerfil).length > 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.BLOQUEIAEXAMEPERFIL'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMBLOQUEIOPERFIL'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                }
                else if(this.examesSelecionados.filter(x => x.id == id).length > 0){
                    return null;                               
                } 
                else {
                    this.examesSelecionados.push({                    
                        id: id,
                        nome: exame.apelido
                    });                    
                }

                this.$refs.procurarExameTextoInput.focus();
            },
            confirmarAlteracaoDataAgendas(agenda){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataAgendamento,
                    events: {
                        alteracaoDataAgendas: this.alteracaoDataAgendas
                    }, 
                    props: {
                        agenda:agenda
                    },                                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            }, 
            
            eventoSelecionado(){
                const eventosSelecionados = [];

                // Filtrar os eventos selecionados
                this.agendas.forEach(a => {
                        const agendaSelecionada = a.eventosDia.filter(x=>x.selecionado)[0]; 
                        
                        if(agendaSelecionada){                            
                            eventosSelecionados.push(agendaSelecionada);                 
                        }
                });

                return (eventosSelecionados.length > 0);
            },            
            cancelarAgendamentos(){                
                    this.agendas.forEach(a => { 
                        
                        a.eventosDia =  a.eventosDia.filter(a=> (!a.encaixe));
                        a.eventosDia.forEach(a => {                                                                   
                            a.bloquearAgenda = false;
                            a.selecionado = false; 
                            a.descricaoExame="";                       
                        });                        
                    });
                    
                    this.eventosSalvar = [];
            },

            async buscarRegrasAgendamento(examesIds,evento){
                
                let quantidadeSlots;
                
                await this.$http.get('/api/agenda/BuscarRegrasAgendamento{?executanteId,tipoAgendamentoId,examesIds*}', {
                    params: {              
                        tipoAgendamentoId: evento.tipoAgendamentoId,  
                        executanteId: evento.executanteId,          
                        examesIds: examesIds.map(x => x)
                    }
                }).then(res => res.data).then(data => {                        
                        quantidadeSlots=data;
                }).catch(e => {
                            throw e;
                });

                return quantidadeSlots;

            },              
            async verificarRegrasSlot(examesIds,evento){
                                
                const quantidadeSlots = await this.buscarRegrasAgendamento(examesIds,evento);
                
                //Quando tiver N exames, N slots
                if(quantidadeSlots > 1){
                    this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId).forEach(a => { 

                        
                        const dataHoraFim = this.$moment(evento.dataHora).add( (evento.intervaloDuracaoAtendimento * quantidadeSlots), 'm');
                        evento.dataHoraFim = dataHoraFim;                       

                        const escalasEncontradas =  a.eventosDia.filter(e=> this.$moment(e.dataHora) < dataHoraFim 
                                                                        && this.$moment(e.dataHora) >= this.$moment(evento.dataHora) 
                                                                        && e.id==0);                         
                                                
                        if(escalasEncontradas.length < quantidadeSlots ){

                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message:  this.$t('AGENDA.NAOEXISTEHORARIOLIVRE'),
                                    type: 'is-warning',
                                    queue: false
                                });
                                
                                evento.selecionado=false;                                                                                                                            
                             
                        }else{  
                            
                            //Selecionar eventos dos slots
                            escalasEncontradas.forEach(x => { 
                                const escala = a.eventosDia.filter(e=>e.hour== moment(x.dataHora).format('HH:mm'))[0];
                                this.atualizaEventoSelecionado(escala,examesIds);     
                            });  
                            
                            this.eventosSalvar.push({
                                examesIds: examesIds,
                                evento: evento
                            });                          
                        }

                    });
                    
                //1 unico exame, 1 slot
                }else{                
                    this.atualizaEventoSelecionado(evento,examesIds); 
                    this.eventosSalvar.push({
                        examesIds: examesIds,
                        evento: evento
                    });                                                           
                }

                                  
                this.VerificarBloqueioAgendas(); 
                
            },
            atualizaEventoSelecionado(evento,examesIds){
                evento.selecionado= true;
                evento.descricaoExame = this.examesSelecionados.filter(x=> examesIds.includes(x.id) ).map(e => e.nome).join(" , ");                      
            },
            async verificarAgenda(evento){                
                if(this.examesSelecionados.length<=0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.NENHUMEXAMESELECIONADO'),
                        type: 'is-warning',
                        queue: false
                    });
                    evento.selecionado = false;
                    return true;
                }

                this.isLoading = true;

                if(this.observacoesInspecao)
                    evento.observacao = this.observacoesInspecao;
                
                
                await this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId).forEach(a => {
                                   
                        const equipments = a.eventosDia[0].equipments;                         
                        const testCodes = [];

                        //Em cada agenda pode ter N equipamentos
                        //Adiciona todos testCodes feitos pela Agenda                        
                        equipments.forEach(q => {
                            q.testCodeConfig.forEach(t => {                                
                                testCodes.push(t);
                            });
                            
                        });

                        //verificar se algum exame selecionado ja foi agendado
                        const examesIds = [];                            
                        this.examesSelecionados.map(e => parseInt(e.id)).forEach(exameId=>{  
                            if(testCodes.filter(t=>t.lisTestCode == exameId).length>0){
                                let NaoAgendado = true;                          
                                this.eventosSalvar.forEach(evento => {                                    
                                    if(evento.examesIds.filter( ex => ex == exameId).length>0)
                                        NaoAgendado = false;
                                })

                                if(NaoAgendado)
                                    examesIds.push(exameId);                                    
                            }

                        });
                        
                        if(examesIds.length>0){
                            this.verificarRegrasSlot(examesIds,evento);                                                                                                                                                                             
                        }else{
                            setTimeout(() => {
                                evento.selecionado=false;
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: this.$t('AGENDA.EXAMESJAAGENDADOS'),
                                    type: 'is-warning',
                                    queue: false
                                })                                
                            },500);    
                        }                        
                                                                                        
                });
                                
                await this.VerificarBloqueioAgendas();                                        
                
                this.isLoading = false;
                                
            },     
            async VerificarBloqueioAgendas(agenda){
                
                //Se todos exames ja agendados, bloquear todas agendas
                if(await this.todosExamesAgendados()){
                        this.agendas.forEach(a => {                                            
                            a.eventosDia.forEach(a => {                                                                   
                                a.bloquearAgenda = true;                        
                            });                        
                        });                
                }else{

                    this.agendas.forEach(a => {       
                        
                        if(this.eventosSalvar.filter(e=>e.evento.tipoAgendamentoId == a.tipoAgendamentoId).length>0){
                            a.eventosDia.forEach(d => {                                                                   
                                d.bloquearAgenda = true;                        
                            });                             
                        }
                        
                    });  
                }                
            },
            alteracaoDataAgendas(novaData,agenda){
                if(agenda){
                    agenda.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));               
                    agenda.proximo = 0; 

                    this.carregarDataDia(agenda);
                    
                }else{
                    this.agendas.forEach(a => {
                        a.proximo = 0; 
                        a.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));     
                        this.carregarDataDia(a);    
                    }); 
                    
                }                               
            },                                            
            proximoDia(dia,agenda){                              
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);                                                
                agenda.proximo = 0;                 
                this.carregarDataDia(agenda);                                                               
            }, 
            proximoDiaVago(dia,agenda){                                   
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);
                agenda.proximo = dia;                                
                this.carregarDataDia(agenda);                                
            },                  
            carregarDataDia(agenda){ 
                
                this.isLoading = true;                
                const params = [];                
                if (agenda.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(agenda.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (agenda.tipoAgendamentoId) params.push(`tipoAgendamentoId=${agenda.tipoAgendamentoId}`);                
                if (this.executanteIdAgendar) params.push(`executanteId=${this.executanteIdAgendar}`);   

                this.$http.get(`/api/agenda/AgendarDia?${params.join('&')}`)
                .then(res => res.data)
                .then(data => {
                    agenda.eventosDia = data?.eventos                                    
                        .map(d => {
                            return this.getEvento(d);
                        });

                    this.isLoading = false;    
                    this.$forceUpdate();                          
                })
                .finally(() => {                                    
                    if(agenda.eventosDia.length==0){
                        if(this.contador >7){
                            this.contador=1;
                        }else if(agenda.proximo>0){
                            agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + 1); 
                            this.contador++;
                            this.carregarDataDia(agenda);
                        }else if (agenda.proximo<0){
                            agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() - 1); 
                            this.contador++;
                            this.carregarDataDia(agenda);
                        }                                                                                
                        return;                                
                    }
                    this.atualizaEventosSalvar(); 
                    this.$forceUpdate(); 
                });                
                                
            },      

            loadDataDia(){
                const params = [];
                              
                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);                                     
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                if (this.filtrosAgendamentoId.length > 0) params.push('filtroTipoAgendamento=' + this.filtrosAgendamentoId.map(item => item).join());
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/Agendar?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {    

                                data.forEach((a, index) => {
                                    a.eventosDia = a.eventosDia.map(d => { return this.getEvento(d); });
                                    a.dataDiaFiltro =  new Date(this.$moment(a.dataDiaFiltro).toDate().setHours(0,0,0,0));
                                });                                
                                
                                this.agendas = data;

                            }                            
                        }).catch(e => {
                            throw e;
                        }).finally(() => {  
                            this.atualizaEventosSalvar();                                  
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },                                              
            getDayOfWeek(dataDiaFiltro) {
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },   
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora),
                    selecionado: false,
                    bloquearAgenda: false,
                    descricaoExame: ""
                }
            },
            loadTiposAgendamentos(){
                const params = [];
                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());                
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/tiposAgendamentoAtivos?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {                            
                            if(this.examesLaboratorial){
                                this.tiposAgendamentoAtivos = data.filter(x=>x.testCodes.length==0);                            
                            }else{
                                this.tiposAgendamentoAtivos = data.filter(x=>x.testCodes.length>0);                             
                            }                                                                                                                                  
                        }).catch(e => {
                            throw e;
                        }).finally(() => {  
                            this.atualizaEventosSalvar();                                  

                            //Tratamento quando tem vários exames selecionados e remove algum, para remover tambem as agendas vinculadas aquele exame removido
                            this.filtrosAgendamentoId = this.filtrosAgendamentoId.filter( f=> this.tiposAgendamentoAtivos.map(e => parseInt(e.id)).includes(f) );
                            
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },            
           
            tipoAgendamentoStyle(tipo) {
                return 'color: #' + tipo.cor + '!important'
            },

            async salvarAgendamentos(){
                
                if(this.convenioIdAgendar == null){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.CONVENIOOBRIGATORIO'),
                        type: 'is-warning',
                        queue: false
                    });
                    return true;
                }

                if(this.eventosSalvar<=0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.NENHUMHORARIOSELECIONADO'),
                        type: 'is-warning',
                        queue: false
                    });
                    return true;
                }

                //Verifica se todos exames selecionados foram agendados                
                if(!(await this.todosExamesAgendados())){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('AGENDA.EXAMESNAOAGENDADOS'),
                                type: 'is-warning',
                                queue: false
                            });
                }else{
                    
                    if(this.eventosSalvar.length>0){
                        
                        this.eventosSalvar.forEach(x => {
                            this.incluirNovoHorarioDia(x.evento,x.evento.dataHora, false, x.examesIds);
                        });

                        this.$router.push({ name: 'pacientes', params: {procurar: null, criarGuia: !this.config.naoGerarGuiaAposInspecao, 
                        agendamento: this.eventosSalvar, observacoesInspecao: this.observacoesInspecao, convenioIdAgendar: this.convenioIdAgendar, agendamentoDashboard:false } })
                        
                    } 
                                   
                }
                              
            },    
            async todosExamesAgendados(){
                
                let retorno = true;

                this.examesSelecionados.map(e => parseInt(e.id)).forEach(exameId=>{  
                    let NaoAgendado = true; 
                                                                                    
                    this.eventosSalvar.forEach(evento => {                                                            
                        const existeAgendamento = evento.examesIds.filter( ex => ex == exameId).length;                            
                        if(NaoAgendado && existeAgendamento>0){                                                                            
                            NaoAgendado = false;
                        }                            
                    });

                    if(NaoAgendado)
                        retorno = false;

                });
                
                return retorno;

            },        
            incluirNovoHorarioDia(evento, horario,bloqueio, examesIds){      
                const params = {};
                
                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId = evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario = moment(horario).toDate(); 

                if(bloqueio){
                    this.adicionarAgendamento(evento, false, examesIds);
                }

                //Novo paciente redirecionamento listagem paciente
                if(evento.equipments.length > 0) {
                    this.adicionarAgendamento(evento, true, examesIds);                
                } //Novo paciente Padrão
                else{                                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })  

                }                  
            },
            async adicionarAgendamento(evento, equip, examesIds) {

                const params = [];

                if(examesIds != null){
                    params.push(`examesIds=${examesIds.join(',')}`)
                }

                if (evento.id) params.push(`id=${evento.id}`);

                if (evento.dataHoraFim) params.push(`dataHoraFim=${moment(evento.dataHoraFim).format('YYYY-MM-DDTHH:mm:ss')}`);

                evento.tipoAgendamentoId == null 
                    ? params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`)
                    : params.push(`tipoAgendamentoId=${evento.tipoAgendamentoId}`) 

                evento.pacienteId == null 
                    ? params.push(`pacienteId=${evento.paciente.id}`) 
                    : params.push(`pacienteId=${evento.pacienteId}`)

                params.push(`convenioId=${this.convenioIdAgendar}`)

                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);

                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);

                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);

                params.push(`observacao=${evento.observacao ? evento.observacao : 'Reservado'}`);

                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                if (this.id && evento.observacao!='Bloqueio' ) params.push(`guiaId=${this.id}`);
                
                try {
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if(equip) {                            
                            evento.id = data.id;                            
                        }
                        else if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    });   

                   
                    this.loadDataDia();
                    
                                            
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },                      
        }
    }
</script>