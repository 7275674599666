<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">Cancelar Fatura</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">Atenção !</h5>
                <p>Este procedimento irá cancelar está fatura.</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoFatura"
                            type="is-danger">
                {{ erroCancelamentoFatura }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field label="Motivos">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosCancelamentoFatura"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosCancelamentoFatura = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoCancelamentoFaturaSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoFatura" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">            
            <b-button type="is-danger" :loading="salvandoDados"
                      @click="confirmarCancelamentoFatura">Confirmar cancelamento</b-button>
            <b-button @click="$emit('close')" :loading="salvandoDados">Cancelar</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            model: Object,
            isLoading: Boolean
        },
        data() {
            return {
                procurarMotivosCancelamentoFatura: '',
                listaMotivosCancelamentoFatura: [],
                motivoCancelamentoFaturaSelecionado: [],
                erroCancelamentoFatura: null,
                salvandoDados: false,
            }
        },
        computed: {
            filteredMotivosCancelamentoFatura() {
                return this.procurarMotivosCancelamentoFatura
                    ? this.listaMotivosCancelamentoFatura
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoFatura.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoFatura
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoFatura()
        },
        methods: {
            loadListaMotivosCancelamentoFatura() {
                this.$http.get('/api/search/MotivosCancelamentoFatura')
                    .then(m => {
                        this.listaMotivosCancelamentoFatura = m.data
                    })
            },
            confirmarCancelamentoFatura() {
                this.salvandoDados = true;
                this.$http.post('/api/financeiro/cancelaFaturamento', {
                    receberId: this.model.id,
                    motivoIds: this.motivoCancelamentoFaturaSelecionado
                })
                .then((res) => {
                    if (res.body.erro) {
                        this.erroCancelamentoFatura = res.body.erro
                    } else {
                        this.$emit('close');
                        // this.isLoading = false;
                        // this.$emit('isLoading');
                        this.$router.push({ name: "financeiro" });
                    }
                }).finally(() => {  
                    this.salvandoDados = false;
                });
            },

        }
    }
</script>