<template>
    <div class="panel">
        <div class="panel-heading">
            {{$t('FILTROS.FILTRO')}}
        </div>
        <div class="panel-block columns is-multiline">
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.funcionario.id" :item.sync="filtros.funcionario" table="Funcionario" :label="$t('SISTEMA.FUNCIONARIO')"/>
            </div>
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.medico.id" :item.sync="filtros.medico" table="Medico" :label="$t('SISTEMA.SOLICITANTE')"/>
            </div>
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.convenio.id" :item.sync="filtros.convenio" table="Convenio" :label="$t('SISTEMA.CONVENIO')"/>
            </div>
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.tipoConvenio.id" :item.sync="filtros.tipoConvenio" table="TipoConvenio" :label="$t('SISTEMA.TIPOCONVENIO')"/>
            </div>
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.unidade.id" :item.sync="filtros.unidade" table="Unidade" :label="$t('SISTEMA.UNIDADE')"/>
            </div>
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.setor.id" :item.sync="filtros.setor" table="Setor" :label="$t('SISTEMA.SETOR')"/>
            </div>
            <div class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.exame.id" :item.sync="filtros.exame" table="Exame" :label="$t('SISTEMA.EXAME')"/>
            </div>

            <div class="column is-6">
                <div class="panel">
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': filtros.dataFiltro == 'solicitacao' }" @click="filtros.dataFiltro = 'solicitacao'">{{$t('FILTROS.DATASOLICITACAO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'recebimento' }" @click="filtros.dataFiltro = 'recebimento'">{{$t('FILTROS.DATARECEBIMENTO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'assinatura' }" @click="filtros.dataFiltro = 'assinatura'">{{$t('FILTROS.DATAASSINATURA')}}</a>
                    </p>
                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="filtros.datas[0]"
                                :dataFinal.sync="filtros.datas[1]"/>
                    </div>
                </div>
            </div>
            
            <div class="column is-4 is-full-mobile">
                <b-switch v-model="filtros.ocultarValorBruto">
                    {{$t('FILTROS.OCULTARVALORBRUTO')}}
                </b-switch>
            </div>

            <div class="column is-6 is-full-mobile" v-if="(tipo=='RepasseMedico')">
                <label class="label">{{$t('FILTROS.SITUACAO')}}</label>
                <div class="control" style="width:100%">
                    <span class="select is-empty" style="width:100%">

                        <select :placeholder="$t('FILTROS.SITUACAO')" v-model="filtros.filtrarPor" style="width:100%;">
                            <option value="todos" checked>{{$t('FILTROS.TODOS')}}</option>
                            <option value="somenteSemRepasse">{{$t('FILTROS.SEMREPASSE')}}</option>
                            <option value="somenteComRepasse">{{$t('FILTROS.COMREPASSE')}}</option>
                        </select>
                    </span>
                </div>
            </div>
        </div>
        <div class="panel-block">
            <b-button icon-left="magnify" type="is-primary" :loading="loading" @click="filtros.exportarPdf=false; carregarResultados()">{{$t('FILTROS.BUSCAR')}}</b-button>           
            <b-button v-if="habilitarBotaoPdf" icon-left="eye" class="ml-1" type="is-success"  @click=" filtros.exportarPdf=true; carregarResultados();" >{{$t('FILTROS.PDF')}}</b-button>                    
        </div>
    </div>
</template>

<style scoped>
    hr{
        margin: 0.5rem 0  !important;
    }

    .label-filtros{
        min-height: 36px !important;
        font-size: 15px !important;
    }
</style>

<script>
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        components: {
            searchIdName,
            periodoHora
        },
        props: {            
            tipo: String,
            habilitarBotaoPdf: {
                type: Boolean,
                default: false
            }            
        },
        data() {
            return {
                filtros: {
                    dataFiltro: 'solicitacao',
                    filtrarPor: 'todos',
                    exportarPdf: false,
                    funcionario: {
                        id: null
                    },
                    tipoConvenio: {
                        id: null
                    },
                    medico: {
                        id: null
                    },
                    unidade: {
                        id: null
                    },
                    convenio: {
                        id: null
                    },
                    setor: {
                        id: null
                    },
                    exame: {
                        id: null
                    },
                    datas: [
                        this.$moment().subtract(7, 'days').toDate(),
                        this.$moment().toDate()
                    ],
                    ocultarValorBruto: false
                },
                loading: false,
            };
        },
        methods: {
            carregarResultados(){
                this.$emit('filtrar', this.filtros);
            },            
        }
    }

</script>